
















/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-this-alias,@typescript-eslint/no-explicit-any,
@typescript-eslint/no-empty-function */
import Vue from 'vue';
import { jsPDF } from 'jspdf';
import {
    init, generate, nextStep, setup,
} from './snowflake';
// @ts-ignore
import schematic from './assets/schematic.jpg';

export default Vue.extend({
    name: 'App',
    data() {
        return {
            topMargin: 40,
            bottomMargin: 50,
            sideMargin: 20,
            cutoutsRatio: 1.7,
            triangleData: null,
        };
    },
    mounted() {
        this.setSizes();
        this.$nextTick(() => {
            this.generate();
        });
    },
    methods: {
        setSizes() {
            const finalSize = Math.min(
                document.documentElement.clientWidth - this.sideMargin * 2,
                document.documentElement.clientHeight
                - this.topMargin - this.bottomMargin,
            ) * 2;
            const cFin = document.getElementById('cFinal') as HTMLCanvasElement;
            cFin.width = finalSize;
            cFin.height = finalSize;

            const canvas = document.getElementById('canvas') as HTMLCanvasElement;
            init(this.topMargin, this.bottomMargin);

            const root = document.documentElement;
            root.style.setProperty('--main-canvas-width', `${canvas.width}px`);
            root.style.setProperty('--main-canvas-height', `${canvas.height}px`);
            root.style.setProperty('--final-canvas-size', `${finalSize}px`);
        },
        generate() {
            // generate pattern
            setup(Number(this.cutoutsRatio));
            generate();

            let result = 0;
            while (result !== -2) {
                result = nextStep(1);
            }
        },
        generateImage() {
            const canvas = document.getElementById('canvas') as HTMLCanvasElement;
            const ci = document.getElementById('imageCanvas') as HTMLCanvasElement;
            const ctx = ci.getContext('2d') as CanvasRenderingContext2D;
            ctx.resetTransform();

            // fill with white
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, ci.width, ci.height);

            // prepare
            const scale = ci.width / (canvas.height * 2);
            ctx.scale(scale, scale);
            const startX = (ci.width / scale) / 2 - canvas.width / 2;
            const nextX = startX + canvas.width;

            // draw triangle
            ctx.lineWidth = 2;
            ctx.fillStyle = '#dedede';
            ctx.strokeStyle = '#eeeeee';
            ctx.beginPath();
            ctx.moveTo(startX, 0);
            ctx.lineTo(nextX, 0);
            ctx.lineTo(startX + canvas.width / 2, canvas.height);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();

            // cut out
            ctx.drawImage(canvas, startX, 0);

            // draw line for help
            ctx.strokeStyle = '#000000';
            ctx.setLineDash([10, 10]);
            ctx.beginPath();
            ctx.moveTo(0, ci.width / scale);
            ctx.lineTo(ci.width / scale, ci.width / scale);
            ctx.stroke();

            // load schema and draw it
            const schImg = new Image();
            schImg.onload = () => {
                const imgScale = Math.min(
                    (ci.width / scale) / schImg.width,
                    ((ci.height - ci.width) / scale) / schImg.height,
                );
                const imgWidth = schImg.width * imgScale;
                const imgHeight = schImg.height * imgScale;
                ctx.drawImage(
                    schImg,
                    (ci.width / scale - imgWidth) / 2,
                    ci.width / scale,
                    imgWidth,
                    imgHeight,
                );

                // create pdf
                // eslint-disable-next-line new-cap
                const doc = new jsPDF();
                doc.addImage(ci.toDataURL('image/jpeg'), 0, 0, 210, 297);
                doc.save('snowflake.pdf');
            };
            schImg.src = schematic;
        },
    },
});
